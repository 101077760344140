import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditSuspension from 'src/components/edit-suspension/edit-suspension';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const PlayerProfileEditSuspensionPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/players/:id">
      <PlayerProfileEditSuspension path="/edit-suspension" />
    </Router>
  );
};

interface PlayerProfileEditSuspensionRouteProps extends RouteComponentProps {
  id?: string;
}

const PlayerProfileEditSuspension: React.FC<PlayerProfileEditSuspensionRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Edit suspension" />
      <EditSuspension id={id} />
    </Layout>
  );
};

export default PlayerProfileEditSuspensionPage;
